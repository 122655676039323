import React from 'react';
import { Link } from 'react-router-dom';

export const NavBar = () => {
  return (
    <nav>
      <ul>
        <div>START HERE - </div>
        <li><Link to="/Home">Home</Link></li>
        <li><Link to="/About">About UWW</Link></li>
        <li><Link to="/Services">Hospitality/Helps</Link></li>
        <li><Link to="/Testimonial">Our Story, Your Story, His Story</Link></li>
        <li><Link to="/News">What Happened!?</Link></li>
        <li><Link to="/Contact">Reach Out-In/ Hand up</Link></li>

      </ul>
    </nav>
  );
};
export default NavBar;