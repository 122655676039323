import React from "react";

function Testimonial()
{
    return(
      <><div>
      <head>
      </head>
            <main className="AboutMain">
              <body>
             
                <div className="AboutDiv">
                <h4>
                The Story of Your Life Purpose!</h4>
                <hr className="Divider"></hr>
                  <p className='AboutPara'>
                  Welcome to a place where our stories intertwine with His—the story of Jesus Christ on the cross. Though each of us may walk different paths, our journeys are woven together through faith in Him, the Creator of us all. In this shared tapestry, we find unity, love, and purpose as we trust in the One who holds the world in His hands.
                  </p>

                  <div className="img-container">
            <div className="img-box">
              <img src="Cross.png" alt="Cross" />
            </div>
          </div>
                    
                </div>
              </body>
            </main>
    </div></>
    )
}
export default Testimonial;