import React from 'react';

const Hello = () => {
    return (
        <div className="title">
            <section>
                <a href='null'>
                    <img src="UWWlogo.png" width={200} height={155} alt="Logo" />
                </a>
            </section>
            <section>
                <h1>UNCLE WALLY'S WORLD</h1>
                <h5>
                    <span style={{ color: 'violet' }}>#LET'S</span>
                    <span style={{ color: 'skyblue' }}>COLOR</span>
                    <span style={{ color: 'slateblue' }}>THE</span>
                    <span style={{ color: 'peach' }}>WORLD</span>
                </h5>
                <h5>
                    <span style={{ color: 'red' }}>#LOVEMAKESTHEWORLDGOROUNDTOUR</span>
                </h5>
                <a href="https://www.youtube.com/watch?v=Jou9ZUG67K0">
                    <img src="LOV_LogoSmall.png" alt="Logo" />
                </a>
                <h4>THE HEART OF THE VISION, CIRCA 2014!</h4>
                <a href="https://www.youtube.com/watch?v=Jou9ZUG67K0">CLICK HERE</a>
            </section>
        </div>
    );
};

export default Hello;