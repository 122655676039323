import React from 'react';
import './Planets.css';

const planets = [
  { id: 2, name: 'Planet 2', imageUrl: 'planet2.png', className: 'planet-2', link:'https://docs.google.com/document/d/1TCFfTRvhWmq8M9EfpJ9sriFC2fa7_dGXll35JKkjNRk/edit?tab=t.0'},
  { id: 3, name: 'Planet 3', imageUrl: 'planet3.png', className: 'planet-3', link:'http://unclewallysworld.com/village.html'},
  { id: 4, name: 'Planet 4', imageUrl: 'planet4.png', className: 'planet-4', link:'https://hnhennouni.typeform.com/to/PWDiKM'},
  { id: 5, name: 'Planet 5', imageUrl: 'planet5.png', className: 'planet-5', link:'https://drive.google.com/file/d/1zn28PqzXa2Dq18LY0qTvBECHEhKjugqN/view'},
  { id: 6, name: 'Planet 6', imageUrl: 'planet6.png', className: 'planet-6', link:'https://docs.google.com/document/d/1VELyk3GUGjdGZmMveLViGdgu3qTDSNkVrQTKP-XqSMM/edit?pli=1&tab=t.0'},
  { id: 7, name: 'Planet 7', imageUrl: 'planet7.png', className: 'planet-7', link:'https://www.iwanciw.com/search'},
  { id: 8, name: 'Planet 8', imageUrl: 'planet8.png', className: 'planet-8', link:'https://docs.google.com/document/d/1-a1jIZTVNRtgxo5PJ7-60aSACIvjMuJ1LqZMBRfViRM/edit?tab=t.0'}
];

const Planets = () => {
  return (
    <div className="planets">
      <img src="hm.png" alt="null"/>
      {planets.map(planet => (
        <section
          key={planet.id}
          className={`planet-item ${planet.className}`}
        >
          <a
            href={planet.link || '#'}
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={planet.imageUrl} alt={planet.name} />
          </a>
        </section>
      ))}
    </div>
  );
};

export default Planets;