import React from "react";

function Services()
{
    return(
      <><div>
      <head>
      </head>
            <main className="AboutMain">
              <body>
                <div className="AboutDiv">
                <h4>
                SHOW UP! - SIGN UP! - PUT UP!
                      </h4>
                      <hr className="Divider"></hr>
                      <p className='AboutPara'>
                   
                  </p>
          <div className="img-container">
            <div className="img-box">
              <img src="villagehome.jpg" alt="Village Home" />
            </div>

          </div>
                </div>
              </body>
            </main>
    </div></>
    )
}
export default Services;