import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Hello from './Components/Hello';
import Planets from './Components/Planets';
import News from './Pages/News';
import AboutUWW from './Pages/AboutUWW';
import NavBar from './Pages/NavBar';
import './App.css';
import Services from './Pages/Services';
import Testimonial from './Pages/Testimonial';
import Contact from './Pages/Contact';
import Home from './Pages/Home';

function App() {
  return (
    <Router>
      <React.Fragment>
        <Hello />

        {/* MENU SIDE BUTTON DROP DOWN --IN PROGRESS-- */}
        {/* 
         import React, { useState } from 'react';

         const [menuOpen, setMenuOpen] = useState(false);
         const toggleMenu = () => { setMenuOpen(!menuOpen) };
         
        <div id="menu">
          <button className="menu-button" onClick={toggleMenu}>Menu</button>
          <ul>
            <li><a href="/about">About UWW</a></li>
            <li><a href="/village">Village</a></li>
            <li><a href="/contact">Contact</a></li>
            <li><a href="/login">Login</a></li>
            <li><a href="/signup">Signup</a></li>
          </ul>
        </div>
         */}

        <section>
          <div>
            <Planets />
          </div>
        </section>

        <NavBar />

        <Routes>
          <Route path="/home" element={<Home />} />
          <Route path="/about" element={<AboutUWW />} />
          <Route path="/services" element={<Services />} />
          <Route path="/testimonial" element={<Testimonial />} />
          <Route path="/news" element={<News />} />
          <Route path="/contact" element={<Contact />} />
        </Routes>

        {/* Info Section */}
        <section className="InfoSection">
          <div className="info-left">
            <h2>Join Us As We Help Others!</h2>
          </div>

          <div className="info-middle">
            <ul className="info-list">
              <li>
                <i className="fa fa-map-marker" aria-hidden="true"></i>
                <span>560 55th St, West New York, NJ 07093</span>
              </li>
              <li>
                <i className="fa fa-phone" aria-hidden="true"></i>
                <span>Call 201-417-7488</span>
              </li>
              <li>
                <i className="fa fa-envelope" aria-hidden="true"></i>
                <span>wally@iwanciw.com</span>
              </li>
            </ul>

            <div className="info_social">
              <a href="https://www.facebook.com/IWANCIW.FF?mibextid=LQQJ4d">
              <img src="Facebooklogo.png" alt="FB" /> <i className="fa fa-facebook" aria-hidden="true"></i>
              </a>
              <a href="https://www.linkedin.com/company/iwanciw-functional-funware/">
              <img src="linkedinLogo.png" alt="Let's Color The World" /><i className="fa fa-linkedin" aria-hidden="true"></i>
              </a>
              <a href="https://instagram.com/iwanciwfunware?igshid=MzRlODBiNWFlZA==">
              <img src="instagram.png" alt="Let's Color The World" /> <i className="fa fa-instagram" aria-hidden="true"></i>
              </a>
            </div>
          </div>

          <div className="info-right">
            <h1>Let's Spread The Good News!</h1>
          </div>
        </section>

        <footer className="footer">
          <p className="text-footer">
            Copyright © 2024 All Rights Reserved.
            Design by UNCLE WALLY'S WORLD
          </p>
        </footer>
      </React.Fragment>
    </Router>
  );
}

export default App;