import React, { useState } from 'react';

const Contact = () => {
  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true); // Start loading state

    // Netlify will handle form submission automatically.
    setTimeout(() => {
      setMessage('Submission successful!');
      setLoading(false); // End loading state
      setName('');
      setPhone('');
    }, 1000); // Simulating success after 1 second
  };

  return (
    <div>
      <section className="Contact">
        <div>
          <h2>Love Everyone, Everyone Needs a "Lifeline" + Help!</h2>
          <h2>Especially Us!</h2>
        </div>
        <div>
          <form onSubmit={handleSubmit} name="contact" method="POST" data-netlify="true">
            <input type="hidden" name="form-name" value="contact" />
            <p>
              <label>
                Name{' '}
                <input
                  type="text"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  placeholder="Enter your name"
                  required
                  name="name"
                />
              </label>
            </p>
            <p>
              <label>
                Phone Number{' '}
                <input
                  type="tel"
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                  placeholder="Enter your phone number"
                  required
                  name="phone"
                />
              </label>
            </p>
            <p>
              <button type="submit" disabled={loading}>
                Send
              </button>
            </p>
          </form>
          {loading && <p>Loading...</p>}
          {message && <p>{message}</p>}
        </div>
      </section>
    </div>
  );
};

export default Contact;