import React from "react";

function About() {
  return (
    <div>
      <main className="AboutMain">
        <div className="AboutDiv">
        <section>
                <h4>
                    <span style={{ color: 'violet' }}>#LET'S</span>
                    <span style={{ color: 'skyblue' }}>COLOR</span>
                    <span style={{ color: 'slateblue' }}>THE</span>
                    <span style={{ color: 'peach' }}>WORLD</span>
                </h4>
              
            </section>
          <hr className="Divider" />
          <p className="AboutPara">
            Welcome to Uncle Wally's World, a community built on love, peace, and the teachings of Jesus.
            We believe in spreading kindness and unity to people from every corner of the globe. 
            Uncle Wally's World is a place where everyone is invited to join in countless activities and collaborations, 
            fostering meaningful connections and shared purpose. 
            With arms open to all, Uncle Wally is seeking like-minded followers who are ready to embrace love and walk together on a path of peace and unity.
            Come be part of a revolution that brings hope and joy to the world!
          </p>
          <div className="img-box">
            <h4>Press Play!</h4>
            <video controls>
              <source src={`${process.env.PUBLIC_URL}/UWWintro.mp4`} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>
        </div>
      </main>
    </div>
  );
}

export default About;