import React from 'react';

function Home() {
  return (
    <div>
      <main className="AboutMain">
        <div className="AboutDiv">
          <h4 className="HomeT">What Color Did God Create You In?</h4>
          <hr className="Divider" />
          <p className="AboutPara">  
GOD created us on purpose, for a purpose! <br /><br />

As a Reflection of Himself, In His Image!  <br /><br />

PERFECT - PURE - HOLY! 

For Eternity - His Desire!
<br /><br />
Value Your "Fruit of The Spirit!" and Find Your True Color!
          </p>

          <h4>Please take our "See Your Color Experience"! (SYCE)
          (click on the planet displayed)</h4>
          <img 
  src="colortheory.jpeg" 
  alt="Color Theory" 
  style={{ width: '675px', height: 'auto' }} // Adjust the width and height as needed
/>
        </div>
      </main>
    </div>
  );
}

export default Home;
