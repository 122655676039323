import React from 'react';

function News() {
  return (
    <>
      <div>
        <head></head>
        <main className="AboutMain">
          <body>
            <div className="AboutDiv">
              <h4>Now That We Have Your Attention!</h4>
              <h4>Can We Talk? </h4>
              <hr className="Divider"></hr>
              <p className='AboutPara'>
                Let's not miss the opurtunity to be of value to eachother!<br /><br />
                Unity/Community is key to healthy living! <br /><br />

                - Take the time! <br />
                There is none anymore! <br /><br />

                - Think lovely! <br />
                Have the mind/compassion of Jesus! <br /><br />

                - Be consistent! <br />
                Make your yes/yes & no/no! <br /><br />

                - Accept don't cancel <br />
                Kick out negative fear mentality! <br /><br />

                - Eliminate all toxins immediately! <br />
                Mentally, emotionally, physically & spiritually! <br /><br />

                - Eat well, sleep well, exercise daily! <br />
                Love & take care of yourself! <br /><br />

                - Always speak Truth in Love! <br />
                Because God is 💘 1st! <br />
              </p>
            </div>
          </body>
        </main>
      </div>
    </>
  );
}

export default News;